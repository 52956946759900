@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/elevation';
@use '@sats-group/ui-lib/tokens/light';

.boot-camp-modal {
  $breakpointMedium: 500px;
  $breakpoint: 600px;

  &__top {
    margin-bottom: spacing.$l;
    margin-top: -(spacing.$l);
    margin-left: -(spacing.$m);
    margin-right: -(spacing.$m);
    background: light.$background-secondary-default;
    border-radius: corner-radius.$m corner-radius.$m 0 0;

    @media(min-width: $breakpointMedium) {
      margin-left: -(spacing.$l);
      margin-right: -(spacing.$l);
    }
  }

  &__top-inner {
    padding: spacing.$l 0;
    display: flex;
    gap: spacing.$l;
  }

  &__header {
    margin-bottom: spacing.$s;
  }

  &__top-indicator {
    min-height: 100%;
    width: 16px; // NOTE: Fixed custom width;
    border-radius: 0 corner-radius.$m corner-radius.$m 0;
    background-color: light.$ge-workouts-bootcamp;
  }

  &__text-alternate {
    color: light.$on-surface-primary-alternate;
  }

  &__accordion {
    background: light.$background-secondary-default;
    border-radius: corner-radius.$s;
  }

  &__toggle {
    appearance: none;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: inherit;
    color: inherit;
    display: flex;
    align-items: center;
    width: 100%;
    padding: spacing.$s;
  }

  &__accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__accordion-content {
    border-top: 1px solid light.$ge-divider-default;
  }

  &__accordion-info {
    display: flex;
    align-items: center;
  }

  &__accordion-info-name {
    margin-bottom: spacing.$xxs;
  }

  &__accordion-inner{
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
    padding: spacing.$s;
  }

  &__accordion-toolbar {
    appearance: none;
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 corner-radius.$s corner-radius.$s;
    width: 100%;
    border-top: 1px solid light.$ge-divider-default;
    padding: spacing.$s;
    color: light.$on-buttons-on-link-default;

    &:hover {
      background-color: light.$buttons-link-hover;
      color: light.$on-buttons-on-link-hover;
    }
  }
}
